import React, { useState } from "react";

import styled from "styled-components";
import { Container, Row } from "react-bootstrap";
import SEO from "../../components/seo";
import Layout from "../../components/Blog/Layout";
import { Title, Button, Box, Input } from "../../components/Blog/Core";
import axios from "axios";

const FormStyled = styled.form``;

const ApplyJob = ({ pageContext }) => {
  const { post } = pageContext;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [message, setMessage] = useState("");
  const [applicationSent, setApplicationSent] = useState(false);

  const onApply = (event) => {
    event.preventDefault();
    const data = {
      name: name,
      email: email,
      linkedin: linkedin,
      message: message,
    };

    const apiUrl =
      typeof window !== undefined && typeof window.location !== undefined
        ? window.location.protocol + "//api." + window.location.host
        : process.env.API_URL;
    axios.post(`${apiUrl}/blog/jobs/apply`, data);

    setApplicationSent(true);
  };

  return (
    <React.Fragment>
      <SEO title={`MARK AI - Apply to ${post.title}`} />

      <Layout>
        <Container>
          <Row className="align-items-center" style={{ marginTop: 200 }}>
            {!applicationSent && (
              <FormStyled name="apply" onSubmit={onApply}>
                {/* You still need to add the hidden input with the form name to your JSX form */}
                <input type="hidden" name="form-name" value="apply" />

                <Box mb={5}>
                  <Title>Envoyer ma candidature</Title>
                </Box>
                <Box mb={3}>
                  <Title
                    variant="card"
                    fontSize="18px"
                    as="label"
                    htmlFor="nameput"
                  >
                    Prénom et Nom
                  </Title>
                  <Input
                    type="text"
                    placeholder="Prénom Nom"
                    name="name"
                    onChange={(event) => setName(event.target.value)}
                    required
                  />
                </Box>
                <Box mb={3}>
                  <Title
                    variant="card"
                    fontSize="18px"
                    as="label"
                    htmlFor="nameput"
                  >
                    Email
                  </Title>
                  <Input
                    type="email"
                    placeholder="Adresse email"
                    name="email"
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                </Box>
                <Box mb={3}>
                  <Title
                    variant="card"
                    fontSize="18px"
                    as="label"
                    htmlFor="nameput"
                  >
                    URL de ton profil Linkedin
                  </Title>
                  <Input
                    type="url"
                    placeholder="URL profil Linkedin"
                    name="linkedin"
                    onChange={(event) => setLinkedin(event.target.value)}
                    required
                  />
                </Box>
                <Box mb={3}>
                  <Title
                    variant="card"
                    fontSize="18px"
                    as="label"
                    htmlFor="nameput"
                  >
                    Ajouter un message
                  </Title>
                  <Input
                    type="text"
                    as="textarea"
                    placeholder="Ajouter un message"
                    rows={4}
                    name="message"
                    onChange={(event) => setMessage(event.target.value)}
                    required
                  />
                </Box>

                <Button width="100%" borderRadius={10} type="submit">
                  Envoyer
                </Button>
              </FormStyled>
            )}
            {applicationSent && (
              <Box mb={5}>
                <Title>Merci! On revient vers toi très vite!</Title>
              </Box>
            )}
          </Row>
        </Container>
      </Layout>
    </React.Fragment>
  );
};
export default ApplyJob;
